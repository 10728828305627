/* Screen Style */

@media screen {
  @import "main";
}

/* Print Style */

@media print {
  @import "print";
}