// This is a partial.
// It lies in /_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

/* Theme */

// color scheme - Navy and Ivory by hal - https://terminal.sexy/#Ahsh6N_WAyw2wkVOfL-einpjLjNA_1h5RLWx8vG5Bl9z71hHotmxvrCQYXeN_5mhntnY9vbJ

:root {
    --background-color: rgb(39, 40, 34);
    --code-color: rgba(0, 0, 0, 0.2);
    --foreground-color: rgb(117, 113, 94);
    --foreground-color-transparent: rgba(248, 248, 242, 0.50);
    --text-color: rgb(248, 248, 242);
    --link-color: rgb(244, 191, 117);
    --h1-color: rgb(244, 191, 117);
    --h2-color: rgb(157, 101, 255);
    --link-color-transparent: rgba(174, 129, 255, 0.25);
    --link-color-active: rgb(243, 217, 180);
    --success-color: rgb(166, 226, 46);
    --info-color: rgb(102, 217, 239);
    --warn-color: rgb(244, 191, 117);
    --danger-color: rgb(249, 38, 114);
    --red-shift: rgba(255, 0, 0, 0.75);
    --blue-shift: rgba(0, 0, 255, 0.75);
  }
  
  // reusable elements
  
  @mixin serif {
    font-family: 'Merriweather', serif;
  }

  @mixin monospace {
    font-family: monospace;
  }

  @mixin fancy {
    font-family: 'Jim Nightshade', cursive;
  }
  
  @mixin sm-margin {
    margin: 1rem 0 1rem 0;
  }
  
  @mixin md-margin {
    margin: 2rem 0 2rem 0;
  }
  
  @mixin padding {
    padding: 0.7rem;
  }
  
  // syntaxhHighlighting - Pygments Monokai - https://raw.githubusercontent.com/jwarby/jekyll-pygments-themes/master/monokai.css
  
  figure, code, pre {
    @include monospace;
    margin: 0;
    padding: 0;
    overflow: scroll;
  }
  
  code.highlighter-rouge {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
    background-color: var(--code-color);
  }
  
  .highlighter-rouge {
    background-color: var(--code-color);
    margin: 0 0 2rem 0;
    
    pre.highlight {
      @include padding;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    
    .highlight {
      .hll { background-color: #272822; }
      .c { color: #afafaf } // Comment
      .err { color: #960050; background-color: #1e0010 } // Error
      .k { color: #66d9ef } // Keyword
      .l { color: #ae81ff } // Literal
      .n { color: #f8f8f2 } // Name
      .o { color: #f92672 } // Operator
      .p { color: #f8f8f2 } // Punctuation
      .cm { color: #afafaf } // Comment.Multiline
      .cp { color: #afafaf } // Comment.Preproc
      .c1 { color: #afafaf } // Comment.Single
      .cs { color: #afafaf } // Comment.Special
      .ge { font-style: italic } // Generic.Emph
      .gs { font-weight: bold } // Generic.Strong
      .kc { color: #66d9ef } // Keyword.Constant
      .kd { color: #66d9ef } // Keyword.Declaration
      .kn { color: #f92672 } // Keyword.Namespace
      .kp { color: #66d9ef } // Keyword.Pseudo
      .kr { color: #66d9ef } // Keyword.Reserved
      .kt { color: #66d9ef } // Keyword.Type
      .ld { color: #e6db74 } // Literal.Date
      .m { color: #ae81ff } // Literal.Number
      .s { color: #e6db74 } // Literal.String
      .na { color: #a6e22e } // Name.Attribute
      .nb { color: #f92672 } // Name.Builtin
      .nc { color: #a6e22e } // Name.Class
      .no { color: #66d9ef } // Name.Constant
      .nd { color: #a6e22e } // Name.Decorator
      .ni { color: #f8f8f2 } // Name.Entity
      .ne { color: #a6e22e } // Name.Exception
      .nf { color: #a6e22e } // Name.Function
      .nl { color: #f8f8f2 } // Name.Label
      .nn { color: #f8f8f2 } // Name.Namespace
      .nx { color: #a6e22e } // Name.Other
      .py { color: #f8f8f2 } // Name.Property
      .nt { color: #f92672 } // Name.Tag
      .nv { color: #f8f8f2 } // Name.Variable
      .ow { color: #f92672 } // Operator.Word
      .w { color: #f8f8f2 } // Text.Whitespace
      .mf { color: #ae81ff } // Literal.Number.Float
      .mh { color: #ae81ff } // Literal.Number.Hex
      .mi { color: #ae81ff } // Literal.Number.Integer
      .mo { color: #ae81ff } // Literal.Number.Oct
      .sb { color: #e6db74 } // Literal.String.Backtick
      .sc { color: #e6db74 } // Literal.String.Char
      .sd { color: #e6db74 } // Literal.String.Doc
      .s2 { color: #e6db74 } // Literal.String.Double
      .se { color: #f8f8f2 } // Literal.String.Escape
      .sh { color: #e6db74 } // Literal.String.Heredoc
      .si { color: #e6db74 } // Literal.String.Interpol
      .sx { color: #e6db74 } // Literal.String.Other
      .sr { color: #e6db74 } // Literal.String.Regex
      .s1 { color: #e6db74 } // Literal.String.Single
      .ss { color: #e6db74 } // Literal.String.Symbol
      .bp { color: #f8f8f2 } // Name.Builtin.Pseudo
      .vc { color: #f8f8f2 } // Name.Variable.Class
      .vg { color: #f8f8f2 } // Name.Variable.Global
      .vi { color: #f8f8f2 } // Name.Variable.Instance
      .il { color: #ae81ff } // Literal.Number.Integer.Long
      .gh { } // Generic Heading & Diff Header
      .gu { color: #afafaf; } // Generic.Subheading & Diff Unified/Comment?
      .gd { color: #f92672; } // Generic.Deleted & Diff Deleted
      .gi { color: #a6e22e; } // Generic.Inserted & Diff Inserted
    }
  }
  
  /* Document */
  
  *::selection {
    background-color: var(--info-color);
  }
  
  body {
    height: 100%;
    @include serif;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    background: black url('/assets/images/bg.png');
    background-attachment: fixed;
  }
  
  #content {
    margin: 0;
    height: 100%;
    color: var(--text-color);
    background: var(--background-color);
    overscroll-behavior: none;
    padding: 20px;
  }

  #recent-posts {
    padding: 10px;
    border-style: inset;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* preloader */
  
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
      z-index: 99;
  }
  
  #status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  #loader {
    height: 7rem;
    width: 7rem;
  }
  

  #home-header {
    background-image: url('/assets/images/bg.png');
    background-attachment: fixed;
  }
  // content
  
  #wrapper {
    min-height: 100%;
    width: 100%;
  }
  
  h1 {
    @include fancy;
    margin-bottom: 1rem;
    font-weight: normal;
    letter-spacing: 2px;
    color: var(--h1-color);
  }

  h2 {
    margin-bottom: 1rem;
    letter-spacing: 1px;
    color: var(--h2-color);
  }
  
  a, a:visited {
    color: var(--link-color);
    text-decoration: none;

    &.active {
      color: var(--success-color);
    }
  }

  a:active, a:hover {
    color: var(--link-color-active);
    text-decoration: underline;
  }

  li {
    padding-bottom: 1em;
  }
  
  img, canvas, svg {
    &:not([width]) {
      width: 100%;
      height: auto;
    }
  }
  
  table {
    width: 100%;
    margin: 0 0 2rem 0;
  
    thead {
      text-align: left;
  
      th {
        background: var(--foreground-color);
        padding: 0.5rem;
      }
    }
  
    tbody {
      tr {
        td {
          padding: 0.5rem;
        }
      }
  
      tr:nth-child(even) {
        background: var(--foreground-color-transparent);
      }
    }
  }
  
  nav {
    text-align: center;

    a {
      padding: 15px;
    }
  }

  hr {
    overflow: visible; /* For IE */
    height: 30px;
    border-style: solid;
    border-color: var(--text-color);
    border-width: 1px 0 0 0;
    border-radius: 20px;
    text-align: center;

    &:after {
      content: "§";
      display: inline-block;
      position: relative;
      top: -0.7em;
      font-size: 1.5em;
      padding: 0 0.25em;
      color: var(--text-color);
      background-color: var(--background-color);
    }

    &:before { 
      display: block;
      content: "";
      height: 30px;
      margin-top: -31px;
      border-style: solid;
      border-color: var(--text-color);
      border-width: 0 0 1px 0;
      border-radius: 20px;
    }
  }